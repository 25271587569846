.root {
  --cell-count-width: 22px;
  min-height: 360px; /* 387px for Russia rank > 10 */
}

.right {
  display: flex;
  align-items: center;
}

.right .cell {
  width: var(--cell-count-width);
  margin-left: 8px;
  display: flex;
  justify-content: flex-end;
}

.item {
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.item:first-child {
  margin-top: -2px;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.item > div:not(:last-child) {
  margin-right: 8px;
}

.item .position {
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
  color: var(--color-opacity);
  margin-right: 0 !important;
}

.item .flag {
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
  font-size: 15px;
}

.item .title {
  position: relative;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0 !important;
}

.item .title span {
  display: block;
  background: linear-gradient(to right, var(--color) 75%, rgba(255, 255, 255, 0));
  color: transparent;
  background-clip: text;
}

.item .count, .count {
  flex-shrink: 0;
  flex-grow: 0;
  width: var(--cell-count-width);
  text-align: right;
}

.item .count.gold {
  font-weight: bold;
}

.item .count.silver {
}

.item .count.bronze {
}

.item .count.total {

}

.itemFull {
  cursor: pointer;
}

.itemFull:not(.important) {
  color: var(--color-opacity);
}
