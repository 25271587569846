.root {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(127, 127, 127, 0.5);
  background-position: 50%;
  background-size: cover;
}

.root iframe, .root embed, .root object, .root video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
