.root {
  position: sticky;
  z-index: 2;
  top: 110px;
  padding: 10px 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), #000), url("../../bg-mobile.png");
}

.list {
  display: flex;
  white-space: nowrap;
  font-size: 10px;
  line-height: 28px;
}

.item {
  flex-grow: 1;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-style: italic;
}

.item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.item:not(:first-child) {
  border-left: 0;
}

.item.selected {
  background-color: #fff;
  color: #000;
}
