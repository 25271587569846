.root {
  min-height: 300px;
}

.root > :first-child > div::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF4242;
  margin-right: 5px;
}

.root > :last-child {
  padding: 16px 6px 0 10px;
}

.root::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 16px;
  bottom: 49px;
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
  pointer-events: none;
}

.event {
  display: block;
  font-size: 12px;
  line-height: 15px;
}

.time {
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: 10px;
}

.time::before {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 4px;
}

.content {
  padding-left: 8px;
  border-left: 1px solid #fff;
  margin-left: 5px;
  margin-bottom: 12px;
}

.footer {
  position: sticky;
  bottom: -1px;
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 11px;
  padding-left: 10px;
  margin-right: -6px;
  margin-left: -10px;
}

.button {
  display: inline-block;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 18px;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
}

.button:hover {
  color: #000;
}
