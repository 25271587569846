.root {
}

.title {
  font-weight: bold;
  margin-bottom: 12px;
}

.material {
  display: flex;
  align-items: flex-start;
}

.material:not(:last-child) {
  margin-bottom: 12px;
}

.image {
  display: block;
  margin-right: 16px;
  min-width: 68px;
  width: 20%;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 4px;
}

.text {
  flex-grow: 1;
}
