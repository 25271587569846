.root {
}

@media (min-width: 1024px) {
  .root > :last-child {
    padding: 10px;
    padding-right: 6px;
  }
}

.root::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 16px;
  bottom: 0;
  height: 40px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000);
  border-bottom-left-radius: inherit;
  pointer-events: none;
}

.newsItem {
  display: block;
  margin-bottom: 16px;
}

.newsItem .image {
  margin-bottom: 8px;
}

.newsItem .image img {
  display: block;
  width: 100%;
  border-radius: 4px;
}

.newsItem .title {
  font-size: 12px;
  line-height: 1.35;
  margin-bottom: 8px;
}

.newsItem .leadAnnouncement {
  display: none;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 8px;
}

.newsItem .publishDate {
  font-size: 12px;
  line-height: 1.35;
  color: var(--color-opacity);
}

.newsItem:not(:last-child) {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
