.root {
}

.root > :last-child {
  padding: 4px;
}

.video {
  position: relative;
  padding-bottom: calc(100% * 9 / 16);
}

.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
