.root {
  position: relative;
}

@media (max-width: 1023px) {
  .root {
    position: sticky;
    top: 0;
    z-index: 3;
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.15);
    padding: 5px 0 12px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../bg-mobile.png");
  }
}

@media (min-width: 1024px) {
  .root {
    padding: 25px 0;
  }
}

.root::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(242, 65, 91, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  backdrop-filter: blur(var(--backdrop-filter-blur));
}

@media (max-width: 1023px) {
  .root :global(.content) {
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.root .body {
  position: relative;
  z-index: 2;
}

@media (max-width: 1023px) {
  .root .body {
    height: 93px;
  }
}

@media (min-width: 1024px) {
  .root .body {
    height: 70px;
  }
}

.sport24 {
  position: absolute;
  top: 8px;
  left: 0;
}

.betcity {
  position: absolute;
  display: flex;
  font-style: italic;
  user-select: none;
}

.betcity a {
  display: block;
}

.betcity a:hover, .betcity a:active {
  color: #fff;
}

@media (max-width: 1023px) {
  .betcity {
    top: 4px;
    right: 0;
    display: flex;
    flex-direction: row;
    font-size: 8px;
  }

  .betcity div {
    align-self: flex-end;
    margin-right: 6px;
  }

  .betcity svg {
    width: 64px;
  }
}

@media (min-width: 1024px) {
  .betcity {
    bottom: 8px;
    left: 0;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 22px;
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 1023px) {
  .title {
    top: 40px;
    width: 280px;
  }
}

@media (min-width: 1023px) {
  .title {
    width: 448px;
  }
}

.title .zenText {
  display: block;
  width: 100%;
  height: 100%;
}

.title .zenLogo {
  display: block;
  position: absolute;
  top: 43%;
  left: 59%;
  width: 13%;
  transform: translate(-50%, -50%);
}

@keyframes zenLogoAnimation {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, -70%);
  }
}

@media (min-width: 430px) {
  .title .zenLogo {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-name: zenLogoAnimation;
  }
}

.info {
  position: absolute;
}

@media (max-width: 1023px) {
  .info {
    bottom: 0;
    left: 0;
    text-align: left;
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .info {
    top: 0;
    right: 0;
    text-align: right;
    font-size: 13px;
  }
}

.info .weather {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1023px) {
  .info .weather {
    margin-top: 0.3em;
  }
}

@media (min-width: 1024px) {
  .info .weather {
    margin-top: 0.62em;
  }
}

.info .weather > :last-child {
  margin-left: 1em;
}

.share {
  position: absolute;
  bottom: 0;
  right: 0;
}
