.root {
  position: relative;
  display: grid;
  grid-template-columns: 260px auto 320px;
  grid-gap: 0 var(--block-margin);
  padding: var(--block-margin) 0;
}

.root > div {
  display: flex;
  flex-direction: column;
}

.banner {
  grid-column: 1 / -1;
}

.news {
  height: 0;
  flex-grow: 1;
}

.live {
  height: 0;
  flex-grow: 1;
}
