.root {
  height: 420px;
  cursor: default;
}

.item {
  display: block;
  position: relative;
  padding-top: 8px;
}

.item:first-child {
  margin-top: -8px;
}

.item:not(:last-child)::after {
  content: "";
  display: block;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.item .competition {
  margin-right: -16px;
  font-size: 12px;
  margin-bottom: 6px;
  color: var(--color-opacity);
}

.item .title {
  font-size: 14px;
  position: absolute;
  left: 60px;
  margin-left: 8px;
  top: 28px;
  height: 60px;
  right: 60px;
  display: flex;
  align-items: center;
}

.item .photo {
  width: 60px;
  height: 60px;
}

/* flag */
.item .photo span {
  font-size: 45px;
  margin-top: 7px;
}

/* photo */
.item .photo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* nophoto */
.item .photo svg {
  display: block;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.2);
}

.item .medal {
  position: absolute;
  right: -8px;
  top: 30px;
}

.item .result {
  position: absolute;
  top: 50px;
  right: -8px;
  font-size: 10px;
  line-height: 13px;
  color: #000;
  background-color: var(--color-opacity);
  border-radius: 2px;
  padding: 0 4px;
  white-space: nowrap;
}

.item .date {
  position: absolute;
  right: -8px;
  top: 68px;
  font-size: 12px;
  color: var(--color-opacity);
}
