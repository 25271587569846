.root {
  position: relative;
}

.title {
  position: absolute;
  right: 100%;
  white-space: nowrap;
  margin-right: 13px;
  color: var(--color-opacity);
  font-size: 13px;
  line-height: 20px;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 1023px) {
  .title {
    display: none;
  }
}

.image {
  user-select: none;
  cursor: pointer;
}

.image:hover {
  color: var(--color-yellow);
}

.dropdown {
  position: absolute;
  z-index: 100;
  margin-top: 10px;
  right: -16px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(var(--backdrop-filter-blur));
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.dropdown a {
  display: block;
  padding: 12px;
}
