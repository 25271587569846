.root {
  cursor: default;
  user-select: none;
}

.root::first-letter {
  text-transform: uppercase;
}

.date {
  color: var(--color-opacity);
}

.time {
}
