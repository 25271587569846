:root {
  --color: #fff;
  --color-yellow: #eabd00;
  --color-opacity: rgba(255, 255, 255, 0.5);
  --content-max-width: 1200px;
  --layout-size-padding: 3vw;
  --block-mobile-background-color: #000;
  --block-desktop-background-color: rgba(0, 0, 0, 0.8);
  --block-border-radius: 4px;
  --block-padding: 16px;
  --block-margin: 16px;
  --backdrop-filter-blur: 10px;
}

@media (max-width: 1024px) {
  html {
    background-color: #000;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
}

@media (min-width: 1024px) {
  body {
    background-color: #888;
  }
}

a {
  color: var(--color);
  text-decoration: none;
}

a:hover, a:active {
  color: var(--color-yellow);
}

svg {
  display: block;
  fill: currentColor;
}

.content {
  max-width: var(--content-max-width);
  padding-left: var(--layout-size-padding);
  padding-right: var(--layout-size-padding);
  margin: 0 auto;
}

div[id^=adfox] a {
  display: block;
}
