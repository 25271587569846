.root {
  display: flex;
  align-items: center;
}

.root a {
  display: block;
}

.sport24 {
  display: flex;
}

@media (max-width: 1023px) {
  .sport24 svg {
    width: 91px;
  }
}
