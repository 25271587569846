.root {
  cursor: default;
  user-select: none;
}

.title {
  color: var(--color-opacity);
  margin-right: 0.25em;
}

.root img {
  width: 2.46em;
  vertical-align: middle;
  margin: -1.23em -0.15em -1.23em -0.3em;
}
