.link {
  text-decoration: underline;
}

.image {
  display: block;
  width: 100%;
  max-width: 600px;
}

.list {
  padding-left: 20px;
}

.block {
  margin-bottom: 16px;
}

.twitter {
  max-width: 350px;
}

.instagram {
  max-width: 350px;
}

@media (max-width: 355px) {
  .instagram {
    transform: scale(0.9);
    transform-origin: left;
  }
}
