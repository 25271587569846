.root {
  display: flex;
  align-items: center;
}

.root a {
  display: block;
  padding: 5px 8px;
}

.root a:hover, .root a:active {

}
