.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 1023px) {
  .root {
    background-color: #000;
  }
}

.title {
  font-size: 10px;
  color: var(--color-opacity);
  margin-bottom: 6px;
  user-select: none;
}
