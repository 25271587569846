.root {
  overflow: hidden;
}

.root:not(.show) {
  height: 0;
  margin-bottom: 0;
}

.root.show {
  height: auto;
}

.root > :last-child {
  padding: 10px;
}

.root [id^=adfox] > :first-child {
  margin: auto;
}
