.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.root.video {
  object-fit: cover;
}

.root.image {
  display: none;
  background-image: url("./bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (prefers-reduced-motion: reduce) {
  .root.video {
    display: none;
  }

  .root.image {
    display: block;
  }
}
